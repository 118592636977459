
<template>
  <div class="h-screen flex w-full bg-img" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white bg-dark">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Login</h4>
                  <p>ยินดีต้อนรับสู่ Alex Rental App</p>
                </div>

                <vs-input
                  id="username"
                  name="username"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="ชื่อผู้ใช้งาน"
                  v-model="username"
                  class="w-full no-icon-border"
                />
                <span class="text-danger text-sm">{{ errors.first('username') }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:6|max:15'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  label-placeholder="รหัสผ่าน"
                  v-model="password"
                  class="w-full mt-6 no-icon-border"
                  v-on:keyup="enterToSubmit"
                />
                <span class="text-danger text-sm">
                  {{
                  errors.first("password")
                  }}
                </span>

                <div class="flex flex-wrap justify-between my-5">
                  <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>
                  <router-link to="/pages/forgot-password">Forgot Password?</router-link>-->
                </div>
                <vs-button disabled="disabled" style="background-color:white !important;"></vs-button>
                <!-- <vs-button class="float-right" :disabled="!validateForm" @click="login">Login</vs-button> -->
                <vs-row vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-lg="8"
                    vs-sm="8"
                    vs-xs="12"
                    class="px-2"
                  >
                    <!-- <span
                      @click="redirectoToRegister()"
                      style="color:#0069ff; cursor:pointer"
                    >สมัครใช้งาน</span> -->
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                    class="px-2"
                  >
                    <vs-button class="float-right" @click="login">Login</vs-button>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      checkbox_remember_me: false
    };
  },
  created() {
    // this.$store.dispatch("auth/chechAuthen");
    if (this.$store.state.auth.isAuthen) {
      // this.$router.push("/");
      window.location.href = "/";
    }
  },
  computed: {
    // validateForm() {
    //   return !this.errors.any() && this.username != "" && this.password != "";
    // }
  },
  methods: {
    // redirectoToRegister() {
    //   window.location.href = this.$store.state.regURL;
    // },
    enterToSubmit: function(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    login() {
      // IF ALREADY LOGIN
      // console.log("LOGIN START");

      var payload = { username: this.username, password: this.password };

      this.$store.dispatch("auth/userLogin", {
        userInfo: payload,
        notify: this.$vs.notify
      });
    },
    notifyAlreadyLogedIn() {
      this.$vs.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
    },
    registerUser() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLogedIn();
        return false;
      }
      this.$router.push("/pages/register");
    }
  }
};
</script>

<style lang="scss">
#page-login {
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #1551b1;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
